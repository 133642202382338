/* eslint-disable max-lines */
import {
  type SetStateAction,
  type ReactNode,
  type Dispatch,
  type ComponentProps,
  type MouseEvent,
  useCallback,
  useState
} from 'react';

import { Avatar } from '~/components/avatar';
import { Icon } from '~/components/icon';
import { LoadingSpinner } from '~/components/loading-spinner';
import { useAnalyticsEvent } from '~/utils/analytics';
import { Link } from '~/utils/routing/Link';
import { marker, styled } from '~/utils/styling';

const Container = styled('a', {
  width: '100%',
  border: '0 none',
  borderRadius: '$tiny',
  padding: '$tiny $small',
  textAlign: 'left',
  display: 'none',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start',
  background: 'transparent',
  height: 'auto',
  textDecoration: 'none',
  color: '$dark-1000',
  position: 'relative',
  zIndex: 10,
  cursor: 'pointer',
  transition: 'color .2s, background .2s',
  fontWeight: '$regular',

  '&:focus, &:hover': {
    background: '$s-brand-50'
  },

  '&[disabled], &[aria-disabled]': {
    '&, &:focus, &:hover': {
      cursor: 'default',
      color: '$dark-600',
      background: 'transparent',
      pointerEvents: 'none'
    }
  },

  '&[data-selected="true"]': {
    '&, &:focus, &:hover': {
      background: '$s-brand-800',
      color: '$s-brand-50'
    }
  },

  variants: {
    intent: {
      danger: {
        color: '$s-danger-500',

        '&:focus, &:hover': {
          background: '$s-danger-50'
        }
      }
    },

    visible: {
      true: {
        display: 'flex'
      }
    }
  },

  defaultVariants: {
    visible: true
  }
});

const Inner = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  flex: 1
});

const WrapIcon = styled('div', {
  width: '1rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexShrink: 0,
  margin: '.1rem $tiny .1rem 0',

  '& svg': {
    height: '1.2em',
    width: 'auto'
  }
});

const WrapAvatar = styled('div', {
  margin: '-$tiny $tiny -$tiny -$tiny'
});

const WrapSelectable = styled(WrapIcon, {});

const WrapText = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: 0,
  flex: 1,
  position: 'relative'
});

const Label = styled('span', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  flex: 1
});

const Description = styled('span', {
  fontSize: '$small',
  color: '$dark-600',
  lineHeight: '1.2',

  '[data-selected="true"] &': {
    color: '$light-800'
  }
});

const Separator = styled('span', {
  display: 'flex',
  margin: '$large $small $tiny $small',
  ...marker({ color: 'grey' }),

  'li:first-child &': {
    marginTop: '$small'
  },

  variants: {
    noLabel: {
      true: {
        margin: '$small $small 0',

        'li:first-child &': {
          marginTop: '0'
        }
      }
    }
  }
});

const Loading = styled('span', {
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 2,

  '&:before': {
    content: ' ',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: '$light-1000',
    opacity: 0.5,
    zIndex: 1
  },

  '& > *': {
    position: 'relative',
    zIndex: 1
  }
});

type MenuItemObj = {
  id?: string;
  key?: string;
  icon?: ComponentProps<typeof Icon>['name'];
  avatar?: ComponentProps<typeof Avatar>;
  label?: ReactNode;
  description?: ReactNode;
  onClick?: (e: any) => any | Promise<any>;
  href?: ComponentProps<typeof Link>['href'];
  target?: string;
  rel?: string;
  selectable?: boolean;
  selected?: boolean;
  disabled?: boolean;
  intent?: 'danger';
  visible?: ComponentProps<typeof Container>['visible'];
  prefetch?: boolean;
  isSeparator?: boolean;
};

type MenuItemProps = Omit<ComponentProps<typeof Container>, 'visible'> & {
  item: MenuItemObj;
  setVisible?: Dispatch<SetStateAction<boolean>>;
  element?: HTMLElement;
  analyticsId?: string;
  disableKeyboardInteraction?: boolean;
};

function MenuItem({ item, setVisible, element, analyticsId, disableKeyboardInteraction, ...props }: MenuItemProps) {
  const event = useAnalyticsEvent();

  const [loading, setLoading] = useState(false);

  const handleClick = useCallback(
    async (e: any) => {
      if (analyticsId) {
        event('menu_item_clicked', {
          type: analyticsId,
          text: `${item.id || item.label}`,
          // TODO: ideal future event attirbutes
          menu_id: analyticsId,
          menu_item_id: `${item.id || item.label}`
        });
      }

      const res = item.onClick?.(e);
      if (res?.then) {
        try {
          setLoading(true);
          await res;
        } finally {
          setLoading(false);
        }
      }
    },
    [event, item, analyticsId]
  );

  const handleKeyDown = useCallback(
    async (e: any) => {
      // For a11y: allow items to be activated using the enter key
      if (e.key === 'Enter') {
        await handleClick(e);
        setVisible?.(false);
      }
    },
    [handleClick, setVisible]
  );

  if ('isSeparator' in item) {
    return <Separator noLabel={!item?.label}>{item?.label}</Separator>;
  }

  if ('href' in item && item.href) {
    return (
      <Container
        as={Link}
        href={item.href}
        id={item.id}
        tabIndex={disableKeyboardInteraction ? undefined : -1}
        data-focusable={!item.disabled}
        intent={item.intent}
        visible={item.visible}
        data-selected={!item.selectable && item.selected}
        aria-disabled={item.disabled}
        target={item.target}
        rel={item.rel}
        onClick={handleClick}
        onKeyDown={handleKeyDown}
        data-testid={item.id ? `sidebar-item-${item.id}` : undefined}
        {...props}
      >
        <Inner>
          {item.icon && (
            <WrapIcon>
              <Icon name={item.icon} />
            </WrapIcon>
          )}
          {item.avatar && (
            <WrapAvatar>
              <Avatar size="medium" {...item.avatar} />
            </WrapAvatar>
          )}
          {item.selectable && <WrapSelectable>{item.selected && <Icon name="tick" />}</WrapSelectable>}
          <WrapText>
            <Label>{item.label}</Label>
            {item.description && <Description>{item.description}</Description>}
          </WrapText>
        </Inner>

        {loading && (
          <Loading>
            <LoadingSpinner />
          </Loading>
        )}
      </Container>
    );
  }

  return (
    <Container
      id={item.id}
      tabIndex={-1}
      data-focusable={!item.disabled}
      disabled={item.disabled}
      intent={item.intent}
      visible={item.visible}
      as={'button' as any}
      data-selected={!item.selectable && item.selected}
      data-testid={item.id ? `sidebar-item-${item.id}` : undefined}
      onMouseDown={async (e: MouseEvent) => {
        await handleClick(e);
        if (!e.defaultPrevented) {
          element?.focus?.();
          setVisible?.(false);
        }
      }}
      onKeyDown={handleKeyDown}
      {...props}
    >
      <Inner>
        {item.icon && (
          <WrapIcon>
            <Icon name={item.icon} />
          </WrapIcon>
        )}
        {item.avatar && (
          <WrapAvatar>
            <Avatar size="medium" {...item.avatar} />
          </WrapAvatar>
        )}
        {item.selectable && <WrapSelectable>{item.selected && <Icon name="tick" />}</WrapSelectable>}
        <WrapText>
          <Label>{item.label}</Label>
          {item.description && <Description>{item.description}</Description>}
        </WrapText>
      </Inner>

      {loading && (
        <Loading>
          <LoadingSpinner />
        </Loading>
      )}
    </Container>
  );
}

export { MenuItem };
export type { MenuItemObj };

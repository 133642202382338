import { useCallback } from 'react';
import { v4 as uuid } from 'uuid';

import { useErrorNotification, useNotification } from '~/components/notification';

import type { Answer } from '~/utils/codegen/graphql';

function useDownloadAnswer() {
  const notification = useNotification();
  const errorNotification = useErrorNotification();

  return useCallback(
    async ({ answer }: { answer: RecursivePartial<Answer> }) => {
      if (!answer?.media?.video) {
        return errorNotification.show(new Error('Answer does not have a video to download'));
      }

      const blob = await (await fetch(answer.media.video)).blob();
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${answer.label ?? `Vouch download ${uuid()}`}.mp4`;
      a.click();

      notification.show({
        message: 'Download starting, please wait'
      });
    },
    [errorNotification, notification]
  );
}

export { useDownloadAnswer };

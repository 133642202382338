import flagsmith from 'flagsmith/isomorphic';
import { FlagsmithProvider, useFlags as useFlagsmithFlags } from 'flagsmith/react';
import { useCallback } from 'react';

import type { IFlagsmith, IFlagsmithTrait, IState } from 'flagsmith/types';
import type { PropsWithChildren } from 'react';

type LoadFeatureFlagsArg = {
  email?: string;
  accountId?: string;
  entityId?: string;
  core?: IFlagsmith;
};

async function loadFeatureFlags({
  email,
  accountId,
  entityId
}: LoadFeatureFlagsArg): Promise<Record<any, never> | IState> {
  if (!process.env.NEXT_PUBLIC_FLAGSMITH_CLIENT_KEY) {
    return {};
  }

  await flagsmith.init({
    environmentID: process.env.TEST_FLAGSMITH_CLIENT_KEY || process.env.NEXT_PUBLIC_FLAGSMITH_CLIENT_KEY,
    identity: accountId,
    traits: {
      email: email || null,
      accountId: accountId || null,
      entityId: entityId || null
    }
  });
  return flagsmith.getState();
}

async function loadSSRFeatureFlags({ email, accountId, entityId }: Omit<LoadFeatureFlagsArg, 'core'>) {
  return loadFeatureFlags({ email, accountId, entityId });
}

type FlagsProviderProps = PropsWithChildren<{
  featureFlags?: IState;
}>;

function FlagsProvider({ featureFlags, children }: FlagsProviderProps) {
  if (typeof window !== 'undefined') {
    // For easier debugging, shouldn't really cause any security concerns I think :|
    window.__VOUCH__ = window.__VOUCH__ || {};
    window.__VOUCH__.featureFlags = featureFlags;
  }

  return (
    <FlagsmithProvider flagsmith={flagsmith} serverState={featureFlags}>
      <>{children}</>
    </FlagsmithProvider>
  );
}

function useFlags(flags: string[]) {
  return useFlagsmithFlags(flags);
}

type Traits = {
  [x: string]: IFlagsmithTrait;
};

function useTraits(traits: string[]): [Traits, (values: Traits) => Promise<any>] {
  const state = useFlagsmithFlags([], traits);
  const setState = useCallback((values: Traits) => flagsmith.setTraits(values), []);
  return [state, setState];
}

export { loadFeatureFlags, loadSSRFeatureFlags, FlagsProvider, useFlags, useTraits };

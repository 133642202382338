import { useMemo } from 'react';

import { useAnswerPlayerModal } from '~/features/answers/actions/useAnswerPlayerModal';

import { useDownloadAnswer } from './useDownloadAnswer';

function useAnswerActions() {
  const playerModal = useAnswerPlayerModal();
  const downloadAnswer = useDownloadAnswer();

  return useMemo(
    () => ({
      playerModal,
      downloadAnswer
    }),
    [downloadAnswer, playerModal]
  );
}

export { useAnswerActions };

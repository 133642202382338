import { gql } from '~/utils/codegen';

export const GET_VOUCH_QUERY = gql(/* GraphQL */ `
  query GetVouch($id: ID!) {
    vouch(id: $id) {
      ...TemplateVouch
      id
      type
      name
      hashId
      status
      campaignId
      message
      updatedAt
      createdAt
      approvalStatus
      asyncTasks {
        vouchPrerender {
          id
          type
          progress
          status
          createdAt
        }
      }
      media {
        preview
        thumbnail
      }
      insights {
        title
        summary
      }
      questions {
        items {
          id
          ordinality
          required
          title
          referenceId
          system
          settings {
            type
            orientation
            optional
            maxDuration
            note
          }
          answer {
            duration
            id
            label
            vouch {
              hashId
            }
            asyncTasks {
              transcode {
                id
                type
                progress
                status
              }
            }
            question {
              title
              ordinality
            }
            playCount
            parent {
              name
            }
            createdAt
            playCount
            transcription {
              id
              text
              translation {
                text
              }
              insights {
                summary
                quotes {
                  text
                  themes
                  startOffset
                  endOffset
                  end_time
                  start_time
                }
                subjects
              }
            }
            captions {
              isEdited
            }
            tags {
              items {
                id
                tag {
                  id
                  name
                  description
                  source
                  suggested
                }
              }
            }
            metadata {
              duration
            }
          }
        }
      }
      events {
        items {
          createdAt
          type
          message
          account {
            id
            name
          }
        }
      }
      contact {
        id
        name
        email
        client {
          id
          name
          logoSrc
        }
      }
      account {
        id
        name
      }
      settings {
        approval {
          enabled
        }
        urls {
          watch
          request
        }
        schedules {
          emailReminder {
            enabled
          }
        }
        translation {
          enabled
          language
        }
        cover {
          message
          vouch {
            ...TemplateVouch
            status
          }
        }
        labels {
          requestTips
          title
          submission
          welcome
          recordingTips
        }
      }
      tags {
        items {
          id
          tag {
            id
            name
            description
            source
            suggested
          }
        }
      }
      campaign {
        id
        name
        hashId
      }
      composition {
        id
        config
      }
    }
  }
`);

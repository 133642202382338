import { gql } from '@apollo/client';

export const TEMPLATE_INSTANCE_FRAGMENT = gql`
  fragment Instance on TemplateInstance {
    id
    name
    system
    default
    options
    createdAt
    updatedAt
    meta {
      frameRate
      resolution
      transitionDuration
    }
    templates {
      id
      name
      version
      templateId
      fonts {
        name
        style
        weight
        otf
        woff2
      }
      layouts {
        name
        tree
      }
      content {
        root
        scenes {
          layout
          data
          derived
          options
          duration
        }
      }
    }
    intro {
      id
      questions {
        items {
          id
          title
          answer {
            ...VideoAnswer
          }
        }
      }
    }
    outro {
      id
      questions {
        items {
          id
          title
          answer {
            ...VideoAnswer
          }
        }
      }
    }
  }
`;
